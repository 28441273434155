.layouts {
    text-align: center;
    width: 100%;
    &__toggle {
      display: inline-block;
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 1rem 1rem 0 0;
      top: -40px;
      position: absolute;
      right: 10px;
      width: 200px;
    }
    &__inner {
      display: flex;
      gap: 0.5rem;
      flex-direction: row;
      overflow-x: scroll;
      padding: 0.5rem;
    }
    &__item {
      width: 120px;
      height: 120px;
      @media only screen and (max-width: 640px) {
        height: 100px;
      }
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: content-box;
    
      &:hover {
        cursor: pointer;
      }
    }
    .canvas {
      margin: 0;
      align-self: unset;
      box-shadow: none;
      // width: 140px;
    }
  
    .canvas .canvas__inner {
        max-width: 120px;
        @media only screen and (max-width: 640px) {
          max-width: 100px;
        }
    }
    
  }
  