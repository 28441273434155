.controls {
  width: 100%;
  position: relative;
  &__inner {
    display: flex;
    flex-direction: column;
    border-radius: 0 2rem 2rem 0;
    padding: 0.5rem;
    width: 100%;
    @media only screen and (max-width: 640px) {
      border-radius: 0 1.25rem 1.25rem 0;
    }
  }
  @media only screen and (max-width: 640px) {
    border-radius: 0;
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  // .btn {
  //   @media only screen and (max-width: 640px) {
  //     min-height: 2rem;
  //     height:2rem;
  //   }
  // }
  button {
    margin-top: 0.5rem;
  }
  &:hover {
    opacity: 1;
  }
  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    border: 1px solid;
    margin: 0.25rem 0;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 2rem;
    @media only screen and (max-width: 640px) {
      margin: 0.25rem;
      padding: 0.5rem 0.5rem;
    }
    .label {
      padding: 0;
    }
    label.space-between {
      width: 100%;
      justify-content: space-between;
    }
  }

  
  // @media only screen and (max-width: 640px) {
  //   bottom: 0;
  //   left: auto;
  //   width: 100%;
  // }
}
