.about {
    padding:1rem;
    position: relative;
    svg {
        width:2rem;
        height:2rem;
        fill: #000;
    }
  &--open {
    background-color: rgba(0, 0, 0, 0.5);
    color:white;
    padding-bottom: 3.5rem;
    svg {
        fill: #fff;
    }
    a {
        color:#eee;
    }
  }
  @media only screen and (max-width: 640px) {
    padding:0.5rem;
    svg {
        width:1.5rem;
        height: 1.5rem;
    }
  }
}
