.collection {
  text-align: center;
  width: 100%;
  &__toggle {
    display: inline-block;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 1rem 1rem 0 0;
    top: -40px;
    position: absolute;
    right:220px;
    width: 200px;
  }
  &__inner {
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    overflow-x: scroll;
    padding: 0.5rem;
  }
  &__item {
    width: 120px;
    height: 120px;
    @media only screen and (max-width: 640px) {
      height: 100px;
    }
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    cursor: pointer;

    &__buttons {
      display: flex;
      opacity: 0;
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      width: 100%;
      flex-direction: column;
      z-index: 1;
      align-items: center;
      justify-content: center;
      transition: opacity 100ms ease-in-out;
      button {
        min-height: 2rem;
        height: 2rem;
        padding:0 0.5rem;
        @media only screen and (max-width: 640px) {
          min-height: 1.5rem;
          height: 1.5rem;
          font-size: 0.825rem;
        }
      }
    }
    &:hover {
      .collection__item__buttons {
        opacity: 1;
      }
    }
  }
  .canvas {
    margin: 0;
    align-self: unset;
    box-shadow: none;
  }

  .canvas .canvas__inner {
    max-width: 120px;
    @media only screen and (max-width: 640px) {
      max-width: 100px;
    }
  }
  .canvas--ratio-3x4 .canvas__inner {
    max-width: 96px;
    @media only screen and (max-width: 640px) {
      max-width: 80px;
    }
  }
  .canvas--ratio-9x16 .canvas__inner {
    max-width: 88px;
    @media only screen and (max-width: 640px) {
      max-width: 70px;
    }
  }
}
