@import '../mixins';
.wall--hide-controls {

}
.canvas {
  position: relative;
  align-self: flex-start;
  &--frame {
    border: 1vh solid #2a2a2a;
    padding: 1.5vh;
    .canvas__shapes {
      border: 1px solid #dedede;
    }
  }
  &--frame-2 {
    border: 1vh solid brown;
  }
  &--frame-3 {
    border: 1vh solid lightyellow;
  }
  &--frame-4 {
    border: 2vh solid #2a2a2a;
  }
  &--frame-5 {
    border: 2vh solid brown;
  }
  &--frame-6 {
    border: 2vh solid lightyellow;
  }
  margin: 2rem auto;
  @media only screen and (max-width: 640px) {
    margin: 2rem auto;
  }
  background-color: white;
  box-shadow: 0px 0px 8px #888, inset 0px 0px 8px #ddd;
  &__inner {
    width: 80vh;
    padding-top: 100%;
    position: relative;

    overflow: hidden;
    @media only screen and (max-width: 640px) {
      width: 68vw;
    }
  }
  &--ratio-4x3 {
    .canvas__inner {
      width: 80vh;
      padding-top: 75%;
      @media only screen and (max-width: 640px) {
        width: 68vw;
      }
    }
  }
  &--ratio-3x4 {
    .canvas__inner {
      width: 60vh;
      padding-top: 125%;
      @media only screen and (max-width: 640px) {
        width: 68vw;
      }
    }
  }
  &--ratio-16x9 {
    .canvas__inner {
      width: 80vh;
      padding-top: 57%;
      @media only screen and (max-width: 640px) {
        width: 68vw;
      }
    }
  }
  &--ratio-9x16 {
    .canvas__inner {
      width: 57vh;
      padding-top: 140%;
      @media only screen and (max-width: 640px) {
        width: 68vw;
      }
    }
  }
  &--print-view {
    box-shadow: none;
    margin: 0;
    border: none;
    padding: 0;
    width: auto!important;
    float: left;
    div {
      border: none !important;
    }
    .canvas__inner {
      box-shadow: none;
      width: 800px!important;
      height: 800px!important;
      padding:0;
    }
    .canvas__shapes {
      border: none;
    }
    .canvas__texture {
      background-size: 30%;
    }
    &.canvas--ratio-9x16{
      .canvas__inner {
        height: 800px!important;
        width:450px!important;
      }
    }
    &.canvas--ratio-16x9 {
      .canvas__inner {
        width: 800px!important;
        height: 450px!important;
      }
    }
    &.canvas--ratio-4x3 {
      .canvas__inner {
        width: 800px!important;
        height: 600px!important;
      }
    }
    &.canvas--ratio-3x4 {
      .canvas__inner {
        height: 800px!important;
        width:600px!important;
      }
    }
  }
  .hide {
    display: none;
  }
  &__shapes {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    div {
      transition: all 800ms;
    }
    // box-shadow: inset 0px 0px 5px #888;
  }
  &--tile &__shapes {
      overflow: hidden;
      width: 25%;
      height: 25%;
      border:none;

      &:nth-child(2),
      &:nth-child(6),
      &:nth-child(10),
      &:nth-child(14) {
        left:25%
      }
      &:nth-child(3),
      &:nth-child(7),
      &:nth-child(11),
      &:nth-child(15) {
        left:50%
      }
      &:nth-child(4),
      &:nth-child(8),
      &:nth-child(12),
      &:nth-child(16) {
        left:75%
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        top:25%
      }
      &:nth-child(9),
      &:nth-child(10),
      &:nth-child(11),
      &:nth-child(12) {
        top:50%
      }
      &:nth-child(13),
      &:nth-child(14),
      &:nth-child(15),
      &:nth-child(16) {
        top:75%
      }
  }

  &__shape_0 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: 40%;
  }

  &__shape_1 {
    width: 70%;
    height: 70%;
    position: absolute;
    left: 15%;
    bottom: 3%;
    background-image: url(../img/texture-4.png);
    background-size: 40%;
  }
  &__shape_2 {
    width: 50%;
    height: 50%;
    position: absolute;
    left: 25%;
    bottom: 6%;
    background-image: url(../img/texture-4.png);
    background-size: 40%;
  }
  &__shape_3 {
    width: 36%;
    height: 36%;
    position: absolute;
    left: 32%;
    bottom: 9%;
    background-image: url(../img/texture-4.png);
    background-size: 40%;
  }
  &__shape_4 {
    width: 26%;
    height: 26%;
    position: absolute;
    left: 37%;
    bottom: 12%;
    background-image: url(../img/texture-4.png);
    background-size: 40%;
  }
  &__shape_5 {
    position: absolute;
    background-image: url(../img/texture-4.png);
    background-size: 60%;
  }
  &__shape_6 {
    position: absolute;
    background-image: url(../img/texture-4.png);
    background-size: 40%;
  }
  &__shape_7 {
    position: absolute;
    background-image: url(../img/texture-4.png);
    background-size: 40%;
  }
  &--no-texture {
    div {
      background-image: none;
    }
  }
  &--2 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_2 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 25%;
      bottom: 12.5%;
      top: auto;
      right: auto;
    }
    .canvas__shape_3 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: 12.5%;
      top: auto;
      right: 25%;
    }
    .canvas__shape_4 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 25%;
      bottom: auto;
      top: 12.5%;
      right: auto;
    }
    .canvas__shape_5 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: auto;
      top: 12.5%;
      right: 25%;
    }
  }
  &--3 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_4 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 12.5%;
      bottom: 12.5%;
      top: auto;
      right: auto;
    }
    .canvas__shape_5 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: 12.5%;
      top: auto;
      right: 12.5%;
    }
    .canvas__shape_2 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 12.5%;
      bottom: auto;
      top: 12.5%;
      right: auto;
    }
    .canvas__shape_3 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: auto;
      top: 12.5%;
      right: 12.5%;
    }
  }
  &--4 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 50%;
      height: 50%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_2 {
      width: 50%;
      height: 50%;
      position: absolute;
      right: auto;
      top: auto;
      bottom: 0;
      left: 0;
    }

    .canvas__shape_3 {
      width: 50%;
      height: 50%;
      position: absolute;
      right: 0;
      top: auto;
      bottom: 0;
      left: auto;
    }
    .canvas__shape_6 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 12.5%;
      bottom: 12.5%;
      top: auto;
      right: auto;
    }
    .canvas__shape_7 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: 12.5%;
      top: auto;
      right: 12.5%;
    }
    .canvas__shape_4 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 12.5%;
      bottom: auto;
      top: 12.5%;
      right: auto;
    }
    .canvas__shape_5 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: auto;
      top: 12.5%;
      right: 12.5%;
    }
  }
  &--5 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 50%;
      height: 50%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_2 {
      width: 50%;
      height: 50%;
      position: absolute;
      right: auto;
      top: auto;
      bottom: 0;
      left: 0;
    }

    .canvas__shape_3 {
      width: 50%;
      height: 50%;
      position: absolute;
      right: 0;
      top: auto;
      bottom: 0;
      left: auto;
    }
    .canvas__shape_6 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 25%;
      bottom: 25%;
      top: auto;
      right: auto;
    }
    .canvas__shape_7 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: 25%;
      top: auto;
      right: 25%;
    }
    .canvas__shape_4 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 25%;
      bottom: auto;
      top: 25%;
      right: auto;
    }
    .canvas__shape_5 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: auto;
      top: 25%;
      right: 25%;
    }
  }
  &--6 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_2 {
      position: absolute;
      width:2%;
      height:100%;
      top:0%;
      left:49%;
    }
    .canvas__shape_3 {
      width: 50%;
      height: 25%;
      position: absolute;
      left: auto;
      bottom: 0;
      top: 12.5%;
      right: 25%;
    }
    .canvas__shape_4 {
      width: 50%;
      height: 25%;
      position: absolute;
      left: 25%;
      bottom: 12.5%;
      top: auto;
      right: auto;
    }
   
    .canvas__shape_5 {
      position: absolute;
      width:50%;
      height:5%;
      top:22.5%;
      left:25%;
    }
    .canvas__shape_6 {
      position: absolute;
      width:50%;
      height:5%;
      top:47.5%;
      left:25%;
    }
    .canvas__shape_7 {
      position: absolute;
      width:50%;
      height:5%;
      top:72.5%;
      left:25%;
    }
    
  }
  &--7 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 100%;
      height: 7.5%;
      position: absolute;
      right: 0;
      top: 8.5%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_2 {
      width: 100%;
      height: 7.5%;
      position: absolute;
      right: 0;
      top: 23.5%;
      bottom: auto;
      left: auto;
    }

    .canvas__shape_3 {
      width: 100%;
      height: 7.5%;
      position: absolute;
      right: 0;
      top: 38.5%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_4 {
      width: 100%;
      height: 7.5%;
      position: absolute;
      right: 0;
      top: 53.5%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_5 {
      width: 100%;
      height: 7.5%;
      position: absolute;
      right: 0;
      top: 68.5%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_6 {
      width: 100%;
      height: 7.5%;
      position: absolute;
      right: 0;
      top: 83.5%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_7 {
      display: none;
    }
  }
  &--8 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 100%;
      height: 50%;
      position: absolute;
      right: 0;
      top: 50%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_2 {
      width: 100%;
      height: 25%;
      position: absolute;
      right: 0;
      top: 25%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_3 {
      width: 100%;
      height: 25%;
      position: absolute;
      right: 0;
      top: 50%;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      display: none;
    }
  }
  &--9 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 0;
    }

    .canvas__shape_1 {
      width: 50%;
      height: 100%;
      position: absolute;
      right: 50%;
      top: 0;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_2 {
      width: 25%;
      height: 100%;
      position: absolute;
      right: auto;
      top: 0;
      bottom: auto;
      left: 25%;
    }
    .canvas__shape_3 {
      width: 25%;
      height: 100%;
      position: absolute;
      right: 25%;
      top: 0;
      bottom: auto;
      left: auto;
    }
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      display: none;
    }
  }
  &--10 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 80%;
      height: 80%;
      position: absolute;
      left: 10%;
      bottom: 10%;
    }
    .canvas__shape_2 {
      width: 60%;
      height: 60%;
      position: absolute;
      left: 20%;
      bottom: 20%;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 30%;
      bottom: 30%;
    }
    .canvas__shape_4 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 40%;
      bottom: 40%;
    }
  }
  &--11 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 80%;
      height: 80%;
      position: absolute;
      left: 10%;
      bottom: 10%;
      border-radius: 10%;
    }
    .canvas__shape_2 {
      width: 60%;
      height: 60%;
      position: absolute;
      left: 20%;
      bottom: 20%;
      border-radius: 10%;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 30%;
      bottom: 30%;
      border-radius: 10%;
    }
    .canvas__shape_4 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 40%;
      bottom: 40%;
      border-radius: 10%;
    }
  }
  &--12 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 80%;
      height: 80%;
      position: absolute;
      left: 10%;
      bottom: 10%;
      border-radius: 20%;
    }
    .canvas__shape_2 {
      width: 60%;
      height: 60%;
      position: absolute;
      left: 20%;
      bottom: 20%;
      border-radius: 20%;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 30%;
      bottom: 30%;
      border-radius: 20%;
    }
    .canvas__shape_4 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 40%;
      bottom: 40%;
      border-radius: 20%;
    }
  }

  &--13 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 80%;
      height: 80%;
      position: absolute;
      left: 10%;
      bottom: 10%;
      border-radius: 40%;
    }
    .canvas__shape_2 {
      width: 60%;
      height: 60%;
      position: absolute;
      left: 20%;
      bottom: 20%;
      border-radius: 40%;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 30%;
      bottom: 30%;
      border-radius: 40%;
    }
    .canvas__shape_4 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 40%;
      bottom: 40%;
      border-radius: 40%;
    }
  }
  &--14 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 80%;
      height: 80%;
      position: absolute;
      left: 10%;
      bottom: 10%;
      border-radius: 100%;
    }
    .canvas__shape_2 {
      width: 60%;
      height: 60%;
      position: absolute;
      left: 20%;
      bottom: 20%;
      border-radius: 100%;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 30%;
      bottom: 30%;
      border-radius: 100%;
    }
    .canvas__shape_4 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 40%;
      bottom: 40%;
      border-radius: 100%;
    }
  }
  &--15 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 100%;
      height: 45%;
      position: absolute;
      left: 0;
      top: 12%;
      clip-path: $mountain1;
    }
    .canvas__shape_2 {
      width: 80%;
      height: 45%;
      position: absolute;
      right: 0;
      top: 56%;
      clip-path: $mountain2;
    }
    .canvas__shape_3 {
      width: 56%;
      height: 26%;
      position: absolute;
      left: 1%;
      top: 51%;
      clip-path: $mountain3;
    }

    .canvas__shape_4 {
      width: 58%;
      height: 37%;
      position: absolute;
      left: -2%;
      top: 55%;
      clip-path: $mountain4;
    }
    .canvas__shape_5 {
      width: 20%;
      height: 44%;
      position: absolute;
      left: 5%;
      top: 36%;
      rotate:6deg;
      clip-path: $tree4;
    }
    .canvas__shape_6 {
      width: 20%;
      height: 52%;
      position: absolute;
      left: 70%;
      top: 40%;
      rotate:-8deg;
      transform: scaleX(-1);
      clip-path: $tree1;
    }
    .canvas__shape_7 {
      width: 15%;
      height: 15%;
      position: absolute;
      left: 72%;
      top: 4%;
      clip-path: $circle1;
    }
  }
  &--16 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 5%;
      top: 5%;
      clip-path: polygon(0% 0%, 80% 0%, 100% 20%, 100% 100%, 20% 100%, 0% 80%);
    }
    .canvas__shape_2 {
      width: 32%;
      height: 32%;
      position: absolute;
      left: 5%;
      top: 5%;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 55%;
      top: 5%;
      clip-path: polygon(0% 0%, 80% 0%, 100% 20%, 100% 100%, 20% 100%, 0% 80%);
    }
    .canvas__shape_4 {
      width: 32%;
      height: 32%;
      position: absolute;
      left: 55%;
      top: 5%;
    }

    .canvas__shape_5 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 5%;
      top: 55%;
      clip-path: polygon(0% 0%, 80% 0%, 100% 20%, 100% 100%, 20% 100%, 0% 80%);
    }
    .canvas__shape_6 {
      width: 32%;
      height: 32%;
      position: absolute;
      left: 5%;
      top: 55%;
    }
    .canvas__shape_7 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 55%;
      top: 55%;
      clip-path: polygon(0% 0%, 80% 0%, 100% 20%, 100% 100%, 20% 100%, 0% 80%);
    }
  }
  &--17 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 40%;
      height: 37%;
      position: absolute;
      left: 5%;
      top: 3%;
      clip-path: polygon(0% 8%, 99% 3%, 100% 73%, 78% 99%, 8% 96%, 0% 90%, 1% 46%);
    }
    .canvas__shape_2 {
      width: 30%;
      height: 40%;
      position: absolute;
      left: 58%;
      top: 10%;
      clip-path: polygon(72% 0, 0 90%, 94% 100%);
    }
    .canvas__shape_3 {
      width: 90%;
      height: 5%;
      position: absolute;
      left: 49%;
      top: 40%;
      clip-path: polygon(0% 8%, 99% 3%, 100% 73%, 78% 99%, 8% 96%, 0% 90%, 1% 46%);
      rotate: 90deg;
    }

    .canvas__shape_4 {
      width: 36%;
      height: 36%;
      position: absolute;
      left: 22.5%;
      top: 44%;
      clip-path: $circle1;
    }
    .canvas__shape_5 {
      width: 43%;
      height: 40%;
      position: absolute;
      left: 55%;
      top: 63%;
      clip-path: polygon(
        17% 16%,
        68% 12%,
        79% 12%,
        79% 68%,
        72% 71%,
        48% 72%,
        34% 71%,
        13% 72%,
        11% 47%,
        14% 30%,
        13% 25%,
        20% 25%,
        16% 20%
      );
    }
    .canvas__shape_6 {
      width: 46%;
      height: 11%;
      position: absolute;
      left: 6%;
      top: 87%;
      clip-path: polygon(0% 8%, 99% 3%, 107% 102%, 78% 79%, 9% 76%, 0% 89%, 1% 46%);
    }
    .canvas__shape_7 {
      width: 18%;
      height: 36%;
      position: absolute;
      left: 2%;
      top: 47%;
      clip-path: polygon(10% 2%, 45% 4%, 85% 5%, 84% 89%, 82% 96%, 71% 97%, 64% 95%, 39% 94%, 18% 94%, 12% 92%, 2% 46%);
      -webkit-clip-path: polygon(
        10% 2%,
        45% 4%,
        85% 5%,
        84% 89%,
        82% 96%,
        71% 97%,
        64% 95%,
        39% 94%,
        18% 94%,
        12% 92%,
        2% 46%
      );
      rotate: 4deg;
    }
  }

  &--18 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 120%;
      height: 45%;
      position: absolute;
      left: -20%;
      top: 3%;
      clip-path: polygon(
        4% 5%,
        55% 7%,
        81% 8%,
        94% 6%,
        95% 38%,
        89% 59%,
        78% 77%,
        66% 74%,
        47% 58%,
        40% 67%,
        32% 92%,
        18% 98%,
        1% 96%,
        0% 41%
      );
    }
    .canvas__shape_2 {
      width: 80%;
      height: 40%;
      position: absolute;
      left: 31%;
      top: 42%;
      clip-path: polygon(
        12% 18%,
        56% 18%,
        93% 18%,
        94% 33%,
        89% 37%,
        80% 43%,
        77% 51%,
        71% 56%,
        64% 57%,
        57% 61%,
        50% 71%,
        41% 75%,
        32% 73%,
        24% 80%,
        17% 88%,
        10% 80%,
        6% 66%,
        4% 48%,
        9% 29%
      );
      rotate: 180deg;
    }
    .canvas__shape_3 {
      width: 104%;
      height: 104%;
      position: absolute;
      left: -2%;
      top: -2%;
      clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 100%,
        0% 100%,
        0% 7%,
        5% 7%,
        5% 32%,
        5% 60%,
        4% 81%,
        4% 95%,
        33% 95%,
        64% 96%,
        79% 95%,
        82% 95%,
        88% 95%,
        95% 94%,
        97% 49%,
        96% 42%,
        95% 36%,
        96% 27%,
        96% 23%,
        95% 5%,
        81% 4%,
        65% 4%,
        47% 4%,
        29% 4%,
        8% 4%,
        0 7%,
        0 0
      );
    }

    .canvas__shape_4 {
      width: 48%;
      height: 3%;
      position: absolute;
      left: 45%;
      top: 76%;
      clip-path: polygon(0% 8%, 99% 3%, 107% 102%, 78% 79%, 9% 76%, 0% 89%, 1% 46%);
    }
    .canvas__shape_5 {
      width: 43%;
      height: 11%;
      position: absolute;
      left: 3%;
      top: 77%;
      clip-path: polygon(
        17% 16%,
        68% 12%,
        79% 12%,
        79% 68%,
        72% 71%,
        48% 72%,
        34% 71%,
        13% 72%,
        11% 47%,
        14% 30%,
        13% 25%,
        20% 25%,
        16% 20%
      );
    }
    .canvas__shape_6 {
      width: 76%;
      height: 2%;
      position: absolute;
      left: 3%;
      top: 88%;
      clip-path: polygon(0% 8%, 99% 3%, 107% 102%, 78% 79%, 9% 76%, 0% 89%, 1% 46%);
    }
    .canvas__shape_7 {
      width: 18%;
      height: 26%;
      position: absolute;
      left: 9%;
      top: 52%;
      clip-path: polygon(10% 2%, 45% 4%, 85% 5%, 84% 89%, 82% 96%, 71% 97%, 64% 95%, 39% 94%, 18% 94%, 12% 92%, 2% 46%);
      -webkit-clip-path: polygon(
        10% 2%,
        45% 4%,
        85% 5%,
        84% 89%,
        82% 96%,
        71% 97%,
        64% 95%,
        39% 94%,
        18% 94%,
        12% 92%,
        2% 46%
      );
      rotate: 90deg;
    }
  }
  &--19 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 70%;
      height: 70%;
      position: absolute;
      left: 10%;
      top: 10%;
      clip-path: ellipse(69% 49% at 1% 50%);
    }
    .canvas__shape_2 {
      width: 80%;
      height: 70%;
      position: absolute;
      left: 14%;
      top: 28%;
      clip-path: ellipse(69% 49% at 1% 50%);
      rotate: 180deg;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 52%;
      position: absolute;
      left: 36%;
      top: 15%;
      clip-path: ellipse(69% 49% at 1% 50%);
      rotate: 89deg;
    }

    .canvas__shape_4 {
      width: 48%;
      height: 3%;
      position: absolute;
      left: 45%;
      top: 9%;
      clip-path: polygon(0% 8%, 99% 3%, 107% 102%, 78% 79%, 9% 76%, 0% 89%, 1% 46%);
    }
    .canvas__shape_5 {
      width: 51%;
      height: 23%;
      position: absolute;
      left: 3%;
      top: 79%;
      clip-path: polygon(
        17% 16%,
        68% 12%,
        79% 12%,
        79% 68%,
        72% 71%,
        48% 72%,
        34% 71%,
        13% 72%,
        11% 47%,
        14% 30%,
        13% 25%,
        20% 25%,
        16% 20%
      );
    }
  }
  &--20 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_2 {
      width: 90%;
      height: 55%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: $paper2;
      transform: scale(-1, -1);
    }
    .canvas__shape_1 {
      width: 80%;
      height: 80%;
      position: absolute;
      left: -5%;
      top: 0;
      clip-path: $paper1;
    }
    .canvas__shape_7 {
      width: 60%;
      height: 90%;
      position: absolute;
      left: 6%;
      top: 5%;
      clip-path: $leaf1;
    }
    .canvas__shape_4 {
      width: 48%;
      height: 3%;
      position: absolute;
      left: 15%;
      top: 86%;
      clip-path: polygon(0% 8%, 99% 3%, 107% 102%, 78% 79%, 9% 76%, 0% 89%, 1% 46%);
    }
    .canvas__shape_5 {
      width: 20%;
      height: 30%;
      position: absolute;
      left: 70%;
      bottom: 10%;
      clip-path: $vase2;
    }
    .canvas__shape_3 {
      width: 70%;
      height: 40%;
      position: absolute;
      left: 50%;
      top: 55%;
      clip-path: $paper1;
    }
    .canvas__shape_6 {
      width: 18%;
      height: 26%;
      position: absolute;
      left: 9%;
      top: 52%;
      clip-path: polygon(10% 2%, 45% 4%, 85% 5%, 84% 89%, 82% 96%, 71% 97%, 64% 95%, 39% 94%, 18% 94%, 12% 92%, 2% 46%);
      rotate: 90deg;
    }
  }
  &--21 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 30%;
      height: 80%;
      position: absolute;
      left: 0;
      top: -10%;
      clip-path: $paper5;
    }


    .canvas__shape_2 {
      width: 60%;
      height: 60%;
      position: absolute;
      left: 45%;
      top: -10%;
      clip-path: $paper3;
    }

    .canvas__shape_3 {
      width: 80%;
    height: 110%;
    position: absolute;
    left: 50%;
    bottom: -16%;
      clip-path: $paper4;
    }

    .canvas__shape_4 {
      width: 100%;
      height: 40%;
      position: absolute;
      left: 0%;
      top: -2%;
      clip-path: $stripes1;
    }

    .canvas__shape_5 {
      width: 42%;
      height: 100%;
      position: absolute;
      right: -3%;
      left: auto;
      top: 37%;
      clip-path: $stripes2;
    }

    .canvas__shape_6 {
      width: 68%;
      height: 75%;
      position: absolute;
      left: -3%;
      top: 30%;
      clip-path: $paper1;
    }
    .canvas__shape_7 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 5%;
      bottom:5%;
      // rotate: -180deg;
      clip-path: $circle1;
    }
  }
  &--22 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 60%;
      height: 100%;
      position: absolute;
      left: -2%;
      top: 0%;
      clip-path: $stripes2;
      transform: scale(-1, -1);
    }

    .canvas__shape_2 {
      width: 44%;
      height: 100%;
      position: absolute;
      left: auto;
      top: 20%;
      right: -2%;
      clip-path: $stripes2;
    }
    .canvas__shape_3 {
      width: 60%;
      height: 40%;
      position: absolute;
      left: -2%;
      top: 20%;
      transform: scale(-1, -1);
      clip-path: $stripes2;
    }

    .canvas__shape_4 {
      width: 44%;
      height: 47%;
      position: absolute;
      left: auto;
      top: 0;
      right: -2%;
      clip-path: $stripes2;
    }
    .canvas__shape_5 {
      width: 44%;
      height: 19%;
      position: absolute;
      left: auto;
      top: 54%;
      right: -2%;
      clip-path: $stripes2;
    }
    .canvas__shape_6 {
      width: 60%;
      height: 10%;
      position: absolute;
      left: -2%;
      top: 60%;
      transform: scale(-1, -1);
      clip-path: $stripes2;
    }
    .canvas__shape_7 {
      width: 60%;
      height: 20%;
      position: absolute;
      left: -2%;
      top: 80%;
      transform: scale(-1, -1);
      clip-path: $stripes2;
    }
  }
  &--23 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 50%;
      height: 60%;
      position: absolute;
      left: -1%;
      bottom: -13%;
      clip-path: $paper5;
    }
  

    
    .canvas__shape_2 {
      width: 60%;
      height: 40%;
      position: absolute;
      left: 55%;
      top: -7%;
      clip-path: $paper1;
      rotate: 180deg;
    }

    .canvas__shape_3 {
      width: 50%;
      height: 40%;
      position: absolute;
      left: 8%;
      top: 5%;
      clip-path: $paper5;
    }

    .canvas__shape_4 {
      width: 80%;
      height: 20%;
      position: absolute;
      left: auto;
      top: 40%;
      left: 30%;
      clip-path: $paper1;
    }

    .canvas__shape_5 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: auto;
      top: 55%;
      left: 60%;
      clip-path: $paper6;
    }

    .canvas__shape_6 {
      width: 10%;
      height: 80%;
      position: absolute;
      left: auto;
      top: 10%;
      left: 5%;
      clip-path: $paper3;
    }


    .canvas__shape_7 {
      width: 98%;
      height: 96%;
      position: absolute;
      left: 1%;
      top: 2%;
      clip-path: $leaf4;
    }
  }
  &--24 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: $shape4;
    }

    .canvas__shape_2 {
      width: 70%;
      height: 60%;
      position: absolute;
      left: 15%;
      top: 30%;
      clip-path: $shape3;
    }

    .canvas__shape_3 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 40%;
      top: 6%;
      clip-path: $circle1;
      rotate: -135deg;
    }

    .canvas__shape_4 {
      width: 15%;
      height: 15%;
      position: absolute;
      left: auto;
      top: 8.5%;
      right: 42.5%;
      clip-path: $leaf2;
    }

    .canvas__shape_5 {
      width: 10%;
      height: 40%;
      position: absolute;
      left: 45%;
      top: 60%;
      rotate: 90deg;
      clip-path: $wiggle1;
    }
  }
  &--25 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_4 {
      width: 86%;
      height: 20%;
      position: absolute;
      left: 7%;
      top: 75%;
      clip-path: $shape5;
    }

    .canvas__shape_2 {
      width: 70%;
      height: 60%;
      position: absolute;
      left: 25%;
      top: 13%;
      clip-path: $shape2;
    }

    .canvas__shape_3 {
      width: 33%;
      height: 40%;
      position: absolute;
      left: 6%;
      top: 45%;
      clip-path: $vase1;
    }

    .canvas__shape_1 {
      width: 60%;
      height: 80%;
      position: absolute;
      left: auto;
      top: 20%;
      right: 20%;
      clip-path: $paper1;
    }

    .canvas__shape_5 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 75%;
      top: 4%;
      clip-path: $moon1;
    }
    .canvas__shape_6 {
      width: 10%;
      height: 30%;
      position: absolute;
      left: 15%;
      top: 20%;
      clip-path: $wiggle1;
    }
  }
  &--26 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_4 {
      width: 86%;
      height: 20%;
      position: absolute;
      left: 7%;
      top: 75%;
      clip-path: $shape3;
    }

    .canvas__shape_3 {
      width: 86%;
      height: 20%;
      position: absolute;
      left: 7%;
      top: 52.5%;
      clip-path: $shape3;
      transform: scaleY(-1);
    }

    .canvas__shape_2 {
      width: 86%;
      height: 20%;
      position: absolute;
      left: 7%;
      top: 27.5%;
      clip-path: $shape3;
    }

    .canvas__shape_1 {
      width: 86%;
      height: 20%;
      position: absolute;
      left: 7%;
      top: 5%;
      clip-path: $shape3;
      transform: scaleY(-1);
    }
    .canvas__shape_5 {
      width: 40%;
      height: 48%;
      position: absolute;
      left: 30%;
      top: 2%;
      clip-path: $leaf5;
    }
    .canvas__shape_6 {
      width: 38%;
      height: 47%;
      position: absolute;
      left: 31%;
      top: 50%;
      clip-path: $vase1;
    }
  }
  &--27 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    
    .canvas__shape_1 {
      width: 120%;
      height: 60%;
      position: absolute;
      left: -10%;
      top: 50%;
      clip-path: $paper1;
    }
    .canvas__shape_2 {
      width: 105%;
      height: 20%;
      position: absolute;
      left: -1%;
      top: 75%;
      clip-path: $island1;
    }
    .canvas__shape_3 {
      width: 20%;
      height: 50%;
      position: absolute;
      left: 10%;
      top: 40%;
      clip-path: $tree1;
    }
    .canvas__shape_4 {
      width: 20%;
      height: 43%;
      position: absolute;
      left: 30%;
      top: 34%;
      clip-path: $tree2;
      rotate: 6deg;
    }
    .canvas__shape_5 {
      width: 24%;
      height: 64%;
      position: absolute;
      left: 40%;
      top: 27%;
      clip-path: $tree3;
      rotate: 6deg;
    }
    .canvas__shape_6 {
      width: 20%;
      height: 50%;
      position: absolute;
      left: 63%;
      top: 34%;
      clip-path: $tree4;
      rotate: 6deg;
    }
    .canvas__shape_7 {
      width: 20%;
      height: 20%;
      position: absolute;
      left: 63%;
      top: 4%;
      clip-path: $circle1;
      rotate: 6deg;
    }

  }
  &--28 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    
    .canvas__shape_1 {
      width: 60%;
      height: 50%;
      position: absolute;
      left: 20%;
      top: 40%;
    }
    .canvas__shape_1 {
      width: 60%;
      height: 60%;
      position: absolute;
      top:10%;
      left:20%;
      clip-path: circle()
    }
    .canvas__shape_2 {
      width: 60%;
      height: 50%;
      position: absolute;
      left: 20%;
      top: 40%;
    }
    .canvas__shape_3 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 25%;
      top: 40%;
    }
    .canvas__shape_4 {
      width: 50%;
      height: 50%;
      position: absolute;
      top:15%;
      left:25%;
      clip-path: circle()
    }
    .canvas__shape_5 {
      width: 100%;
      height: 10%;
      position: absolute;
      left: 0;
      top: 90%;
      clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0 100%);
    }
    .canvas__shape_6 {
      width: 30%;
      height: 50%;
      position: absolute;
      left: 35%;
      top: 40%;
    }
    .canvas__shape_7 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: 35%;
      top: 25%;
      clip-path: circle()
    }

  }
  &--29 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 75%;
      height: 80%;
      position: absolute;
      left: 12.5%;
      top: 10%;
    }
    
    .canvas__shape_2 {
      width: 5%;
      height: 65%;
      position: absolute;
      left: 25%;
      top: 17.5%;
    }
    .canvas__shape_3 {
      width: 5%;
      height: 65%;
      position: absolute;
      left: 70%;
      top: 17.5%;
    }
    .canvas__shape_4 {
      width: 40%;
      height: 5%;
      position: absolute;
      left: 30%;
      top: 17.5%;
    }
    .canvas__shape_5 {
      width: 40%;
      height: 5%;
      position: absolute;
      left: 30%;
      top: 37.5%;
    }
    .canvas__shape_6 {
      width: 40%;
      height: 5%;
      position: absolute;
      left: 30%;
      top: 57.5%;
    }
    .canvas__shape_7 {
      width: 40%;
      height: 5%;
      position: absolute;
      left: 30%;
      top: 77.5%;
    }


  }
  &--30 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 20%;
      height: 70%;
      position: absolute;
      left: 0%;
      top: 0%;
    }
    .canvas__shape_3 {
      width: 5%;
      height: 100%;
      position: absolute;
      left: 20%;
      top: 0%;
    }
    
    .canvas__shape_4 {
      width: 50%;
      height: 40%;
      position: absolute;
      left: 50%;
      top: 0%;
    }
    .canvas__shape_5 {
      width: 75%;
      height: 5%;
      position: absolute;
      left: 25%;
      top: 40%;
    }
    .canvas__shape_6 {
      width: 20%;
      height: 5%;
      position: absolute;
      left: 0%;
      top: 70%;
    }
    .canvas__shape_2 {
      width: 75%;
      height: 55%;
      position: absolute;
      left: 25%;
      top: 45%;
    }
    .canvas__shape_7 {
      width: 5%;
      height: 40%;
      position: absolute;
      left: 45%;
      top: 0;
    }
    


  }
  &--31 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
    .canvas__shape_2 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 25%;
      top: 50%;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
    .canvas__shape_3 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 0%;
      top: 50%;
      clip-path: polygon(50% 0, 0% 100%, 100% 100%);
    }
    .canvas__shape_4 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      clip-path: polygon(50% 0, 0% 100%, 100% 100%);
    }
    
    .canvas__shape_5 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 0%;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
    .canvas__shape_6 {
      width: 25%;
      height: 100%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: polygon(100% 50%, 0 100%, 0 0);
    }
    .canvas__shape_7 {
      width: 25%;
      height: 100%;
      position: absolute;
      left: 75%;
      top: 0%;
      clip-path: polygon(0 50%, 100% 100%, 100% 0);
    }
  }
  &--32 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 66.66666%;
      height: 50%;
      position: absolute;
      left: 16.6666%;
      top: -25%;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    .canvas__shape_2 {
      width: 66.66666%;
      height: 50%;
      position: absolute;
      left: -33.3333%;
      top: 0%;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    .canvas__shape_3 {
      width: 66.66666%;
      height: 50%;
      position: absolute;
      left: 16.6666%;
      top: 25%;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    .canvas__shape_4 {
      width: 66.66666%;
      height: 50%;
      position: absolute;
      left: 66.666%;
      top: 0%;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    .canvas__shape_5 {
      width: 66.66666%;
      height: 50%;
      position: absolute;
      left: -33.3333%;
      top: 50%;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    
    .canvas__shape_6 {
      width: 66.66666%;
      height: 50%;
      position: absolute;
      left: 16.6666%;
      top: 75%;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
    .canvas__shape_7 {
      width: 66.66666%;
      height: 50%;
      position: absolute;
      left: 66.666%;
      top: 50%;
      clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
    }
  }
  &--33 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 60%;
      height: 30%;
      position: absolute;
      left: 0%;
      top: 0%;
      border-radius: 0 0 50% 50%/0 0 100% 100%;
      // border-radius: 100% 0 0 100% / 50% 0 0 50%;
    }
    .canvas__shape_2 {
      width: 60%;
      height: 30%;
      position: absolute;
      left: 0%;
      top: 30%;
      border-radius: 50% 50% 0 0/100% 100% 0 0;
    }
    
    .canvas__shape_3 {
      width: 40%;
      height: 80%;
      position: absolute;
      left: 60%;
      top: -20%;
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    }
    .canvas__shape_4 {
      width: 80%;
      height: 40%;
      position: absolute;
      left: 20%;
      top: 60%;
      border-radius: 0 0 50% 50%/0 0 100% 100%;
      // border-radius: 50% 50% 0 0/100% 100% 0 0;
    }
    
    .canvas__shape_5 {
      width: 40%;
      height: 20%;
      position: absolute;
      left: 20%;
      top: 60%;
      border-radius: 0 0 50% 50% / 0 0 100% 100%;
    }
    .canvas__shape_6 {
      width: 40%;
      height: 20%;
      position: absolute;
      left: 60%;
      top: 60%;
      border-radius: 0 0 50% 50% / 0 0 100% 100%;
    }
    .canvas__shape_7 {
      width: 20%;
      height: 40%;
      position: absolute;
      left: 0;
      top: 60%;
      border-radius: 100% 0 0 100% / 50% 0 0 50%;
    }
  }
  &--34 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
    .canvas__shape_2 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 0%;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
    }
    .canvas__shape_3 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 0%;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
    
    
    .canvas__shape_4 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 0%;
      top: 50%;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
    }
    .canvas__shape_5 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 0%;
      top: 50%;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
    
    
    .canvas__shape_6 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
    }
    .canvas__shape_7 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
  }
  &--35 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 4%;
      height: 100%;
      position: absolute;
      left: 48%;
      top: 0%;
    }
    .canvas__shape_2 {
      width: 100%;
      height: 4%;
      position: absolute;
      left: 0%;
      top: 48%;
    }
    .canvas__shape_3 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      clip-path: polygon(0% 0%, 3% 0%, 100% 97%, 100% 100%, 97% 100%, 0% 3%);
    }
    
    
    .canvas__shape_4 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      clip-path: polygon(97% 0%, 100% 0%, 100% 3%, 3% 100%, 0% 100%, 0% 97%);
    }
    .canvas__shape_5 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 30%;
      top: 30%;
      clip-path: circle();
    }
    
    
    .canvas__shape_6 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: -20%;
      top: 30%;
      clip-path: circle();
    }
    .canvas__shape_7 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 80%;
      top: 30%;
      clip-path: circle();
    }
  }
  &--36 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
   
    .canvas__shape_1 {
      width: 100%;
      height: 40%;
      position: absolute;
      left: 0%;
      top: 60%;
    }
    .canvas__shape_2 {
      width: 15%;
      height: 40%;
      position: absolute;
      left: 47.5%;
      top: 20%;
      border-radius: 50% 50% 0 0 / 20% 20% 0 0;
    }
    .canvas__shape_3 {
      width: 100%;
      height: 24%;
      position: absolute;
      left: 0%;
      top: 36%;
    }
    .canvas__shape_4 {
      width: 40%;
      height: 100%;
      position: absolute;
      left: 00%;
      top: 0%;
      clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0 100%, 0% 100%);
    }
    .canvas__shape_5 {
      width: 20%;
      height: 60%;
      position: absolute;
      left: 70%;
      top: 0%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 0 60%);
    }

    .canvas__shape_6 {
      width: 10%;
      height: 60%;
      position: absolute;
      left: 90%;
      top: 0%;
    }
    .canvas__shape_7 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: polygon(0% 0%, 0% 100%, 10% 100%, 10% 10%, 90% 10%, 90% 90%, 10% 90%, 10% 100%, 100% 100%, 100% 0%);
    }
  }
  &--37 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: polygon(0% 0%, 50% 0%, 50% 50%, 100% 50%, 100% 75%, 50% 75%, 75% 75%, 75% 100%, 25% 100%, 25% 75%, 25% 25%, 0% 25%);
    }
    .canvas__shape_2 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 0%;
    }
    .canvas__shape_3 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 00%;
      top: 50%;
      clip-path: polygon( 0% 0%, 0% 0%, 50% 0%, 50% 0%, 50% 50%, 50% 50%, 100% 50%, 100% 50%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
    }
    .canvas__shape_4 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 25%;
      top: 0%;
    }
    .canvas__shape_5 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 50%;
      top: 25%;
    }
    .canvas__shape_6 {
      width: 50%;
      height: 25%;
      position: absolute;
      left: 25%;
      top: 50%;
    }
    .canvas__shape_7 {
      width: 25%;
      height: 25%;
      position: absolute;
      left: 75%;
      top: 75%;
    }

  }
  &--38 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 90%;
      height: 90%;
      position: absolute;
      left: 5%;
      top: 5%;
      clip-path: polygon(0% 0%, 75% 0%, 75% 25%, 50% 25%, 50% 75%, 50% 50%, 100% 50%, 100% 75%, 75% 75%, 75% 100%, 25% 100%, 25% 75%, 25% 50%, 0% 50%);
    }
    .canvas__shape_2 {
      width: 22.5%;
      height: 45%;
      position: absolute;
      left: 27.5%;
      top: 5%;
    }
    .canvas__shape_3 {
      width: 45%;
      height: 67.5%;
      position: absolute;
      left: 5%;
      top: 27.5%;
      clip-path: polygon( 0% 0%, 0% 0%, 50% 0%, 50% 0%, 50% 66.6666%, 50% 66.666%, 100% 66.6666%, 100% 50%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
    }
    .canvas__shape_4 {
      width: 45%;
      height: 45%;
      position: absolute;
      left: 50%;
      top: 27.5%;
      clip-path: polygon( 0% 0%, 100% 0%, 100% 50%, 50% 50%, 50% 100%, 0% 100%);
    }
    .canvas__shape_5 {
      width: 22.5%;
      height: 22.5%;
      position: absolute;
      left: 72.5%;
      top: 50%;
    }
    .canvas__shape_6 {
      width: 45%;
      height: 22.5%;
      position: absolute;
      left: 50%;
      top: 5%;
    }
    .canvas__shape_7 {
      width: 22.5%;
      height: 22.5%;
      position: absolute;
      left: 72.5%;
      top: 72.5%;
    }

  }
  &--39 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 40%;
      height: 50%;
      position: absolute;
      left: 0%;
      top: 20%;
    }
    .canvas__shape_7 {
      width: 5%;
      height: 70%;
      position: absolute;
      left: 40%;
      top: 0%;
    }

    .canvas__shape_3 {
      width: 100%;
      height: 5%;
      position: absolute;
      left: 0%;
      top: 70%;
    }
    
    .canvas__shape_4 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 45%;
      top: 0%;
    }
    
    .canvas__shape_5 {
      width: 55%;
      height: 5%;
      position: absolute;
      left: 45%;
      top: 40%;
    }

    .canvas__shape_6 {
      width: 5%;
      height: 25%;
      position: absolute;
      left: 80%;
      top: 45%;
    }

    .canvas__shape_2 {
      width: 60%;
      height: 25%;
      position: absolute;
      left: 40%;
      top: 75%;
    }
    


  }
  
  &--40 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: 10%;
      top: 5%;
      clip-path: circle();
    }
    .canvas__shape_2 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: 60%;
      top: 5%;
      clip-path: circle();
    }
    .canvas__shape_3 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: -15%;
      top: 35%;
      clip-path: circle();
    }
    .canvas__shape_4 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: 35%;
      top: 35%;
      clip-path: circle();
    }
    .canvas__shape_5 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: 85%;
      top: 35%;
      clip-path: circle();
    }
    .canvas__shape_6 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: 10%;
      top: 65%;
      clip-path: circle();
    }
    .canvas__shape_7 {
      width: 30%;
      height: 30%;
      position: absolute;
      left: 60%;
      top: 65%;
      clip-path: circle();
    }
  }
  &--41 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    .canvas__shape_1 {
      width: 40%;
      height: 10%;
      position: absolute;
      left: 30%;
      top: 85%
    }
    .canvas__shape_2 {
      width: 40%;
      height: 10%;
      position: absolute;
      left: 30%;
      top: 73%;
      rotate: -15deg;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 10%;
      position: absolute;
      left: 30%;
      top: 60%;
      rotate: 12deg;
    }
    .canvas__shape_4 {
      width: 40%;
      height: 10%;
      position: absolute;
      left: 28%;
      top: 45%;
      rotate: -22deg;
    }
    .canvas__shape_5 {
      width: 40%;
      height: 10%;
      position: absolute;
      left: 30%;
      top: 30%;
      rotate: 16deg;
    }
    .canvas__shape_6 {
      width: 40%;
      height: 10%;
      position: absolute;
      left: 29%;
      top: 15%;
      rotate: -20deg;
    }
    .canvas__shape_7 {
      width: 40%;
      height: 10%;
      position: absolute;
      left: 30%;
      top: 5%;
      
    }
  }
  &--42 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      width: 35%;
      height:100%;
      position: absolute;
      clip-path: polygon(0% 0%, 50% 22%, 40% 36%, 92% 60%, 50% 80%, 28% 85%, 0% 100%, 8% 100%, 36% 85%, 58% 80%, 100% 60%, 48% 36%, 58% 22%, 8% 0%);
    }
    
    .canvas__shape_1 {
      left: 5%;
      top: 0%;
    }
    .canvas__shape_2 {
      left: 11%;
      width: 37%;
      top: 0%;
    }
    .canvas__shape_3 {
      left: 17%;
      width: 39%;
      top: 0%;
    }
    .canvas__shape_4 {
      left: 23%;
      width: 41%;
      top: 0%;
   }
    .canvas__shape_5 {
      left: 29%;
      width: 43%;
      top: 0%;
    }
    .canvas__shape_6 {
      left: 35%;
      width: 45%;
      top: 0%;
    }
    .canvas__shape_7 {
      left: 42%;
      width: 47%;
      top: 0%;
    }
  }
  &--43 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      width: 25%;
      height:100%;
      position: absolute;
      clip-path: polygon(0% 0%, 50% 25%, 0% 50%, 50% 75%, 0% 100%, 50% 100%, 100% 75%, 50% 50%, 100% 25%, 50% 0%);
    }
    
    .canvas__shape_1 {
      left: 0%;
      top: 0%;
    }
    .canvas__shape_2 {
      left: 12.5%;
      top: 0%;
    }
    .canvas__shape_3 {
      left: 25%;
      top: 0%;
    }
    .canvas__shape_4 {
      left: 37.5%;
      top: 0%;
   }
    .canvas__shape_5 {
      left: 50%;
      top: 0%;
    }
    .canvas__shape_6 {
      left: 62.5%;
      top: 0%;
    }
    .canvas__shape_7 {
      left: 75%;
      top: 0%;
    }
  }
  &--44 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    .canvas__shape_1 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 0%;
      top: 0%;
      clip-path: circle();
    }
    .canvas__shape_2 {
      width: 25%;
      height: 50%;
      position: absolute;
      left:75%;
      top: 0%;
      border-radius: 100% 0 0 100% / 50% 0 0 50%;
    }
    .canvas__shape_3 {
      width: 25%;
      height: 50%;
      position: absolute;
      left:50%;
      top: 0%;
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    }
    .canvas__shape_4 {
      width: 50%;
      height: 25%;
      position: absolute;
      left: 0%;
      top: 50%;
      border-radius: 0 0 50% 50% / 0 0 100% 100%;
    }
    .canvas__shape_5 {
      width: 50%;
      height: 25%;
      position: absolute;
      left: 0%;
      top: 75%;
      border-radius: 0 0 50% 50% / 0 0 100% 100%;
    }
    .canvas__shape_6 {
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      border-radius: 0 50% 50% 0 / 0 50% 50% 0;
    }
    .canvas__shape_7 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 55%;
      top: 55%;
      clip-path: circle();
    }
  }
  &--45 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    
    .canvas__shape_1 {
      width: 30%;
      height: 100%;
      position: absolute;
      left:22%;
      top: 20%;
      border-radius: 50% 50% 0 0  / 15% 15% 0 0;
    }
    .canvas__shape_2 {
      width: 25%;
      height: 100%;
      position: absolute;
      left:28%;
      top: 21%;
      border-radius: 50% 50% 0 0  / 15% 15% 0 0;
    }

    .canvas__shape_3 {
      
      width: 25%;
      height: 25%;
      position: absolute;
      left:62%;
      top: 7.5%;
      clip-path: $circle1;
    }
    .canvas__shape_4 {
      width: 43%;
      height: 100%;
      position: absolute;
      left:57%;
      top: 40%;
      clip-path: polygon(0% 100%, 0% 0%, 16.6666% 8%, 33.33333% 0%, 50% 8%, 66.6666% 0%, 83% 8%, 100% 0%, 100% 100%);

    }
    .canvas__shape_6 {
      width: 40%;
      height: 50%;
      position: absolute;
      left: 46%;
      top: 9%;
      clip-path: $leaf1;
      transform: scaleY(-1);
    }
    .canvas__shape_7 {
      width: 20%;
      height: 35%;
      position: absolute;
      left: 65%;
      top: 60%;
      clip-path: $vase1;
    }
    .canvas__shape_5 {
      width: 60%;
      height: 60%;
      position: absolute;
      left: 0%;
      top: 40%;
      clip-path: polygon(0% 0%, 20% 0%, 20% 20%, 40% 20%, 40% 40%, 60% 40%, 60% 60%, 80% 60%, 80% 80%, 100% 80%, 100% 100%, 0% 100%);
    }
  }
  &--46 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    
    .canvas__shape_1 {
      width: 30%;
      height: 40%;
      position: absolute;
      left:10%;
      top: 10%;
      clip-path: $moon2;
    }
    .canvas__shape_2 {
      width: 20%;
      height: 17%;
      position: absolute;
      left: 51%;
      top: 43%;
      clip-path: $star1;
    }
    .canvas__shape_3 {
      width: 25%;
      height: 25%;
      position: absolute;
      left:45%;
      top: 5%;
      clip-path: $star2;
    }
    .canvas__shape_4 {
      width: 32%;
      height: 27%;
      position: absolute;
      left:5%;
      top: 55%;
      clip-path: $star3;
    }
    .canvas__shape_5 {
      width: 28%;
      height: 23%;
      position: absolute;
      left: 63%;
      top: 21%;
      clip-path: $star4;
    }
    .canvas__shape_6 {
      width: 26%;
      height: 28%;
      position: absolute;
      left: 35%;
      top: 68%;
      clip-path: $star5;
    }

    .canvas__shape_7 {
      width: 26%;
      height: 28%;
      position: absolute;
      left: 74%;
      top: 59%;
      clip-path: $star6;
    }

  }
  &--47 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    
    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      width: 100%;
      height: 100%;
      clip-path: circle()
    }
    .canvas__shape_1 {
      position: absolute;
      left:-50%;
      top: -50%;
    }
    .canvas__shape_2 {
      position: absolute;
      left:50%;
      top: -50%;
    }
    .canvas__shape_3 {
      position: absolute;
      left:-50%;
      top: 50%;
    }
    .canvas__shape_4 {
      position: absolute;
      left:50%;
      top: 50%;
    }
    .canvas__shape_5 {
      position: absolute;
      width:60%;
      height:60%;
      left:10%;
      top: 20%;
    }
    .canvas__shape_6 {
      position: absolute;
      width:60%;
      height:60%;
      left:30%;
      top: 20%;
    }
    .canvas__shape_7 {
      position: absolute;
      width:60%;
      height:60%;
      left:20%;
      top: 20%;
    }
    
  }
  &--48 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    .canvas__shape_1 {
      width: 80%;
      height: 40%;
      position: absolute;
      left:8%;
      top: 15%;
      clip-path: polygon(1% 1%, 93% 0%, 99% 4%, 99% 96%, 99% 99%, 91% 100%, 87% 97%, 6% 99%, 0% 95%, 1% 90%, 0% 10%);
    }
    .canvas__shape_2 {
      width: 50%;
      height: 40%;
      position: absolute;
      left:15%;
      top: 50%;
      rotate: 8deg;
      clip-path: $paper5;
    }
    .canvas__shape_3 {
      width: 60%;
      height: 40%;
      position: absolute;
      left:5%;
      top: 7%;
      rotate: 8deg;
      clip-path: polygon(0% 0%, 98% 1%, 100% 95%, 3% 100%, 3% 87%, 90% 83%, 88% 11%, 1% 12%); 
    }
    .canvas__shape_4 {
      width: 70%;
      height: 60%;
      position: absolute;
      left:25%;
      top: 30%;
      rotate: 170deg;
      clip-path: polygon(1% 1%, 98% 0%, 100% 90%, 100% 99%, 0% 98%, 0% 92%, 92% 93%, 88% 6%, 1% 7%);
    }
    .canvas__shape_5 {
      width: 40%;
      height: 40%;
      position: absolute;
      left:40%;
      top: 40%;
      clip-path: $shape2;
    }
    .canvas__shape_6 {
      width: 15%;
      height: 15%;
      position: absolute;
      left:6%;
      top: 65%;
      clip-path: $circle1
    }
    .canvas__shape_7 {
      width: 7%;
      height: 47%;
      position: absolute;
      left: 30%;
      top: -1%;
      rotate: 90deg;
      clip-path: $wiggle1
    }

  }

  &--49 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    .canvas__shape_1 {
      width: 50%;
      height: 40%;
      position: absolute;
      left:25%;
      top: 25%;
      clip-path: polygon(0% 98%, 48% 75%, 48% 0%, 52% 0%, 52% 75%, 100% 98%, 100% 100%, 95% 100%, 52% 80%, 48% 80%, 6% 100%, 0% 100%);
    }

    .canvas__shape_2 {
      width: 50%;
      height: 12%;
      position: absolute;
      left:25%;
      top: 35%;
      clip-path: polygon(0% 0%, 0% 6%, 48% 100%, 52% 100%, 100% 6%, 100% 0%, 94% 0%, 52% 82%, 49% 83%,6% 0%);
      
    }

    .canvas__shape_4 {
      width: 50%;
      height: 12%;
      position: absolute;
      left:25%;
      top: 23.5%;
      clip-path: polygon(0% 0%, 0% 6%, 49% 100%, 51% 100%, 100% 6%, 100% 0%, 94% 0%, 52% 82%, 49% 83%,6% 0%);
      transform: scaleY(-1);
    }
   
    .canvas__shape_3 {
      width: 50%;
      height: 10%;
      position: absolute;
      left:25%;
      top: 65%;
      clip-path: polygon(0% 0%, 0% 6%, 48% 100%, 52% 100%, 100% 6%, 100% 0%, 94% 0%, 52% 82%, 49% 83%,6% 0%);
        }
    
    .canvas__shape_5 {
      width: 2%;
      height: 30%;
      position: absolute;
      left:49%;
      top: 45%;
    }
    .canvas__shape_6 {
      width: 2%;
      height: 30%;
      position: absolute;
      left:25%;
      top: 35%;

    }
    .canvas__shape_7 {
      width: 2%;
      height: 30%;
      position: absolute;
      left:73%;
      top: 35%;
    }

  }
  &--50 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    
    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      width: 50%;
      height: 50%;
      
    }
    .canvas__shape_1 {
      position: absolute;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
      left:0;
      top:0;

    }
    .canvas__shape_2 {
      position: absolute;
      left:50%;
      top: 0%;
      clip-path: polygon(0 0, 0 100%, 100% 0);

    }
    .canvas__shape_3 {
      position: absolute;
      left:50%;
      top: 0%;
      clip-path: polygon(100% 100%, 0 100%, 100% 0);
    }
    .canvas__shape_4 {
      position: absolute;
      left:0%;
      top: 50%;
      clip-path: polygon(0 0, 0 100%, 100% 0);

    }
    .canvas__shape_5 {
      position: absolute;
      left:0%;
      top: 50%;
      clip-path: polygon(100% 100%, 0 100%, 100% 0);
    }
    

    .canvas__shape_6 {
      position: absolute;
      left:50%;
      top: 50%;
      clip-path: polygon(100% 100%, 0 0, 100% 0);

    }
    .canvas__shape_7 {
      position: absolute;
      left:50%;
      top: 50%;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }
    
  }
  &--51 {
    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    
    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      width: 100%;
      height: 100%;
      left:0;
      top:0;
    }
    .canvas__shape_1 {
      clip-path: polygon(0% 0%, 25% 0%, 75% 100%, 100% 100%, 100% 75%, 0% 25%);
    }
    .canvas__shape_2 {
      clip-path: polygon(0% 0%, 25% 0%, 75% 100%, 100% 100%, 100% 75%, 0% 25%);
      transform: scaleX(-1)
    }
    .canvas__shape_3 {
      position: absolute;
      width:30%;
      left:35%;
      clip-path: polygon(100% 0%, 0% 100%, 100% 100%, 0% 0%);
    }
    .canvas__shape_4 {
      position: absolute;
      height:30%;
      top:35%;
      clip-path: polygon(0% 0%, 100% 100%, 100% 0%, 0% 100%);

    }

    

    .canvas__shape_5 {
      position: absolute;
      left:30%;
      top: 30%;
      width:40%;
      height:40%;
      clip-path: circle();

    }
    
    .canvas__shape_6 {
      position: absolute;
      left:30%;
      top: 30%;
      width:40%;
      height:40%;
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    }
    .canvas__shape_7 {
      position: absolute;
      left:40%;
      top: 40%;
      width:20%;
      height:20%;
      clip-path: circle();

    }
  }
  &--52 {

    

    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    
    
    .canvas__shape_1 {
      width: 60%;
      height: 40%;
      top:40%;
      left:10%;
    }
    .canvas__shape_2 {
      height:60%;
      width:20%;
      top:20%;
      left: 70%;
      clip-path: polygon(0 100%, 0% 33.333%, 50% 0%, 100% 33.3333%, 100% 90%);

    }
    .canvas__shape_3 {
      position: absolute;
      width:84%;
      height:22%;
      left:8%;
      top:19%;
      clip-path: polygon(0% 100%, 15% 0%, 85% 0%, 100% 100%, 98% 100%, 85% 10%, 74% 100%);

    }
    .canvas__shape_4 {
      position: absolute;
      height:12%;
      width:20%;
      top:45%;
      left:42%;
    }

    .canvas__shape_5 {
      position: absolute;
      height:12%;
      width:17%;
      top:45%;
      left:15%;
    }

    .canvas__shape_6 {
      position: absolute;
      height:18%;
      width:10%;
      top:62%;
      left:18%;
    }

    .canvas__shape_7 {
      position: absolute;
      height:12%;
      width:24%;
      top:64%;
      left:38%;
    }
  }
  &--53 {

    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    
    .canvas__shape_1 {
      width: 130%;
      height: 60%;
      top:25%;
      left:-15%;
      clip-path: $island1;

    }
    
    
    .canvas__shape_2 {
      width: 30%;
      height: 30%;
      top:40%;
      left:10%;
      clip-path: polygon(0 73%, 100% 0, 100% 100%);

    }
    .canvas__shape_3 {
      width: 25%;
      height: 30%;
      top:40%;
      left:40%;
      clip-path: polygon(0 100%, 0 0, 100% 62%);
      
    }
    .canvas__shape_4 {
      width: 20%;
      height: 20%;
      top:30%;
      left:50%;
      clip-path: polygon(0 73%, 100% 0, 100% 100%);

    }
    .canvas__shape_5 {
      width: 17%;
      height: 20%;
      top:30%;
      left:70%;
      clip-path: polygon(0 100%, 0 0, 100% 62%);
      
    }


    .canvas__shape_6 {
      width: 14%;
      height: 15%;
      top:20%;
      left:41%;
      clip-path: polygon(0 73%, 100% 0, 100% 100%);

    }
    .canvas__shape_7 {
      width: 12%;
      height: 15%;
      top:20%;
      left:55%;
      clip-path: polygon(0 100%, 0 0, 100% 62%);
      
    }
  }
  &--54 {

    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
      clip-path: polygon(4% 0, 99% 5%, 95% 100%, 0 95%);
    }
    
    .canvas__shape_1 {
      width: 40%;
      height: 30%;
      top:10%;
      left:5%;
      
    }
    
    
    .canvas__shape_2 {
      width: 40%;
      height: 30%;
      top:12%;
      left:44.5%;
    }
    .canvas__shape_3 {
      width: 40%;
      height: 30%;
      top:40%;
      left:3%;
      
    }
    .canvas__shape_4 {
      width: 40%;
      height: 30%;
      top:42%;
      left:42.5%;

    }
    .canvas__shape_5 {
      width: 40%;
      height: 30%;
      top:70%;
      left:1%;
      
    }


    .canvas__shape_6 {
      width: 40%;
      height: 30%;
      top:72%;
      left:40.5%;

    }
    .canvas__shape_7 {
      width: 40%;
      height: 55%;
      top:45%;
      left:35%;
      clip-path: $lamp;
      transform: scaleY(-1);
    }
  }

  &--55 {

    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
        clip-path: polygon(0% 0, 0% 0%, 0% 50%, 0% 50%, 100% 50%, 100% 50%, 100% 100%, 100% 100%, 50% 100%, 50% 100%, 50% 0%, 50% 0%);
        width: 25%;
        height: 100%;
        top:0%;
    }
    
    .canvas__shape_1 {

      left:0%;
      
    }
    
    
    .canvas__shape_2 {
      left:12.5%;
    }
    .canvas__shape_3 {
      left:25%;
      
    }
    .canvas__shape_4 {
      left:37.5%;
    }
    .canvas__shape_5 {
      left:50%;
    }
    .canvas__shape_6 {
      left:62.5%;
    }
    .canvas__shape_7 {
      left:75%;

    }
  }

  &--56 {

    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
        clip-path: $pointed-oval;
        width: 50%;
        height: 50%;
        top:0%;
    }

    .canvas__shape_1 {
      width:100%;
      left:0%;
      top:49%;
      height:2%;
    }

    .canvas__shape_2 {
      width:2%;
      top:0%;
      left:49%;
      height:100%;
    }
    
    .canvas__shape_3 {

      left:0%;
      
    }
    
    
    .canvas__shape_4 {
      left:50%;
      transform: scaleX(-1);
      
    }
    .canvas__shape_5 {
      left:0%;
      top:50%;
      
      transform: scaleX(-1);

      
    }
    .canvas__shape_6 {
      left:50%;
      top:50%;

    }
    .canvas__shape_7 {
      clip-path: $flower;
      width:30%;
      height:30%;
      left:35%;
      top:35%;
    }
  }
  

  &--57 {

    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
        clip-path: $flower;
        width: 50%;
        height: 50%;
        top:0%;
    }

    .canvas__shape_1 {
      clip-path: circle();
      width: 60%;
      height: 60%;
      left:20%;
      top:20%;
    }
    .canvas__shape_2 {
      left:25%;
      top:25%;
    }

    .canvas__shape_3 {
      top:-25%;
      left:-25%;
    }
    
    .canvas__shape_4 {
      top:75%;
      left:-25%;
    }
    
    
    .canvas__shape_5 {
      left:75%;
      top:-25%
      
    }
    .canvas__shape_6 {
      left:75%;
      top:75%

    }
    
    .canvas__shape_7 {
      clip-path: $flower;
      width:20%;
      height:20%;
      left:40%;
      top:40%;
    }
  }


  &--58 {

    .canvas__shape_0 {
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .canvas__shape_1,
    .canvas__shape_2,
    .canvas__shape_3,
    .canvas__shape_4,
    .canvas__shape_5,
    .canvas__shape_6,
    .canvas__shape_7 {
        clip-path: polygon(0% 0%, 0% 100%, 5% 100%, 5% 5%, 95% 5%, 95% 95%, 5% 95%, 5% 100%, 100% 100%, 100% 0%);

        
    }

    .canvas__shape_1 {
      width: 95%;
        height: 95%;
      top:2.5%;
      left:2.5%;
    }
    .canvas__shape_2 {
      width:80%;
      height: 80%;
      top:10%;
      left:10%;
      clip-path: polygon(0% 0%, 0% 100%, 6% 100%, 6% 6%, 94% 6%, 94% 94%, 6% 94%, 6% 100%, 100% 100%, 100% 0%);

    }

    .canvas__shape_3 {
      width:65%;
      height: 65%;
      top:17.5%;
      left:17.5%;
      clip-path: polygon(0% 0%, 0% 100%, 7% 100%, 7% 7%, 93% 7%, 93% 93%, 7% 93%, 7% 100%, 100% 100%, 100% 0%);

    }
    
    .canvas__shape_4 {
      width:50%;
      height: 50%;
      top:25%;
      left:25%;
      clip-path: polygon(0% 0%, 0% 100%, 10% 100%, 10% 10%, 90% 10%, 90% 90%, 10% 90%, 10% 100%, 100% 100%, 100% 0%);

    }
    
    
    .canvas__shape_5 {
      width:35%;
      height: 35%;
      top:32.5%;
      left:32.5%;
      clip-path: polygon(0% 0%, 0% 100%, 12% 100%, 12% 12%, 88% 12%, 88% 88%, 12% 88%, 12% 100%, 100% 100%, 100% 0%);

    }
    .canvas__shape_6 {
      width:20%;
      height: 20%;
      top:40%;
      left:40%;
      clip-path: polygon(0% 0%, 0% 100%, 20% 100%, 20% 20%, 80% 20%, 80% 80%, 20% 80%, 20% 100%, 100% 100%, 100% 0%);
    }
    
    .canvas__shape_7 {
      width:5%;
      height:5%;
      left:47.5%;
      top:47.5%;
      clip-path: none;
    }
  }
  


  &__texture {
    background-image: url(./../img/texture-2.png);
    background-size: 30%;
    opacity: 0.5;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
.canvas--opacity {
  .canvas__shape_7,
  .canvas__shape_6,
  .canvas__shape_5,
  .canvas__shape_4,
  .canvas__shape_3,
  .canvas__shape_2,
  .canvas__shape_1 {
    opacity: 0.5 !important;
  }
}
