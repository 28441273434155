.colours {
    width: 100%;
    position: relative;
    &__inner {
      display: flex;
      flex-direction: column;
      border-radius: 0 2rem 2rem 0;
      padding: 0.5rem;
      width: 100%;
      @media only screen and (max-width: 640px) {
        border-radius: 0 1.25rem 1.25rem 0;
      }
    }
    @media only screen and (max-width: 640px) {
      border-radius: 0;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
    }
    // .btn {
    //   @media only screen and (max-width: 640px) {
    //     min-height: 2rem;
    //     height:2rem;
    //   }
    // }
    button {
      margin-top: 0.25rem;
    }
    &:hover {
      opacity: 1;
    }

    &__field {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      border: 1px solid;
      margin: 0.25rem 0;
      padding: 0.5rem 1rem;
      background-color: white;
      border-radius: 2rem;
      @media only screen and (max-width: 640px) {
        margin: 0.25rem;
        padding: 0.5rem 0.5rem;
      }
      .label {
        padding: 0;
      }
      label.space-between {
        width: 100%;
        justify-content: space-between;
      }
      &__color {
        padding: 0;
        border: none;
        height: 2rem;
        background-color: transparent;
        width:3.5rem;
        @media only screen and (max-width: 640px) {
          height: 1.75rem;
          width:3rem;
        }
        
      }
    }
    &--canvas--1,
    &--canvas--10,
    &--canvas--11,
    &--canvas--14 {
      .colours__field--5 {
        display: none;
      }
    }
    &__field--6,
    &__field--7 {
      display: none;
    }
    &--canvas--4,
    &--canvas--5,
    &--canvas--6,
    &--canvas--15,
    &--canvas--16,
    &--canvas--17,
    &--canvas--18,
    &--canvas--20,
    &--canvas--21,
    &--canvas--22,
    &--canvas--23,
    &--canvas--27,
    &--canvas--28,
    &--canvas--29,
    &--canvas--30,
    &--canvas--31,
    &--canvas--32,
    &--canvas--33,
    &--canvas--34,
    &--canvas--35,
    &--canvas--36,
    &--canvas--37,
    &--canvas--38,
    &--canvas--39,
    &--canvas--40,
    &--canvas--41,
    &--canvas--42,
    &--canvas--43,
    &--canvas--44,
    &--canvas--45,
    &--canvas--46,
    &--canvas--47,
    &--canvas--48,
    &--canvas--49,
    &--canvas--50,
    &--canvas--51,
    &--canvas--52,
    &--canvas--53,
    &--canvas--54,
    &--canvas--55,
    &--canvas--56,
    &--canvas--57,
    &--canvas--58 {
      .colours__field--6,
      .colours__field--7 {
        display: flex;
      }
    }
    
    &--canvas--25,
    &--canvas--26{
      .colours__field--6 {
        display: flex;
      }
    }
  
    &--canvas--7 {
      .colours__field--6 {
        display: flex;
      }
    }
    &--canvas--8,
    &--canvas--9 {
      .colours__field--4,
      .colours__field--5,
      .colours__field--6,
      .colours__field--7 {
        display: none;
      }
    }
    // @media only screen and (max-width: 640px) {
    //   bottom: 0;
    //   left: auto;
    //   width: 100%;
    // }
  }
  