footer {
  padding:1rem;
  border-top:1px solid #ddd;
  font-size: 0.825rem;
  background-color: #fff;
  background-color: #c3cbdc;
background-image: linear-gradient(90deg, #c3cbdc 0%, #edf1f4 74%);

   @media print {
      display: none;
  }
}
a {
  color: blue;
  text-decoration: underline;
}