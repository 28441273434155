@import '../mixins';
@mixin toggle() {
  display: inline-block;
  cursor: pointer;
  padding: 0.5rem 2rem;
  border-radius: 1rem 1rem 0 0;
  font-size:1.5rem;
  letter-spacing: 1px;
  // font-weight: bold;
  @media only screen and (max-width: 800px) {
    padding: 0.5rem 1rem;
  }
  @media only screen and (max-width: 640px) {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    margin-left:0.1rem;
  }
}
.wall {
  display: flex;
  min-height: 100vh;
  justify-self: center;
  flex-direction: column;
  position: relative;
  width:100%;
  &__canvas {
    display: flex;
  }
  &--print-view {
    .wall__controls {
      display: none!important;
    }
    .wall__grid {
      display: block;
    }
    .wall__tabs {
      display: none;
    }
  }
  @media only screen and (max-width: 640px) {
    padding-bottom: 0;
  }
  box-sizing: content-box;
  &__grid {
    display: grid;
    grid-template-columns: 0px 1fr;
    min-height: 100vh;
    transition: margin-left 140ms ease-in, grid-template-columns 140ms ease-in;
  }
  &--show-controls &__grid {
    grid-template-columns: 180px 1fr;
    margin-left:0;
    @media only screen and (max-width: 640px) {
      grid-template-columns: 160px 1fr;
      .canvas__inner {
        width: 40vw;
      }
    }
  }
  // &--show-tabs &__grid {
  //   padding-bottom: 160px;
  // }
  &__controls {
    position: relative;
    margin-left:-180px;
    transition: margin-left 140ms ease-in;
    @media only screen and (max-width: 640px) {
      margin-left:-160px;
    }
    &--active {
      margin-left:0;
    }
    &__content {
      width:180px;
      height: 100%;
      position: relative;
      z-index: 2;  
      @media only screen and (max-width: 640px) {
        width:160px;
      }
    }
    &__toggles {
      rotate: 90deg;
      position: absolute;
      left: 44px;
      display: flex;
      gap: 1.5rem;
      @media only screen and (max-width: 800px) {
        left: 76px;
      }
      @media only screen and (max-width: 640px) {
        left: 73px;
      }
      top: 10rem;
      z-index: 1;
    }
    &__toggle {
      @include toggle();
      @media only screen and (max-width: 640px) {
        padding-left:1rem;
        padding-right: 1rem;;
      }
    }
    
  }
  &__tabs {
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    margin-bottom:-136px;
    z-index: 3;
    transition: margin-bottom 140ms ease-in;
    &--active {
      margin-bottom:0;
    }
    &__content {
      height:136px;
    }
    &__toggles {
      text-align: right;
      margin-right: 2rem;
      margin-top: -52px;
      float:right;
      display: flex;
      gap: 1.5rem;
      @media only screen and (max-width: 640px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap:0.5rem;
        width: 100%;
        margin-right: 0;
        padding:0 0.5rem;
        margin-top: -40px;
      }
      @media only screen and (max-width: 400px) {
        gap:0.25rem;
        padding:0 0.25rem;
        .wall__tabs__toggle__arrow {
          display: none;
        }
      }
    }
    &__toggle {
      @include toggle();
      margin-left:0.5rem;
    }
  }
  &__quick-edit {
    position: fixed;
    top: 1rem;
    right:1rem;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    justify-content:center;
    @media only screen and (max-width: 640px) {
      flex-direction: row;
      bottom:4rem;
      top:auto;
      right:auto;
      width: 100%;
    }
  }
  .about {
    position: fixed;
    bottom:0rem;
    left: 0rem;
    z-index: 0;
    width:400px;
    max-width: 100%;
    @media only screen and (max-width: 640px) {
      left:auto;
      bottom:auto;
      right:0;
      top:0;
      text-align: right;
    }
  }
}



.print-view {
  &--popup {
    width:100%;
    height:100%;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;
    z-index: 4;
    img,
    canvas {
      max-width: 100%;
    height: auto;
    max-height: 100%;
    height: auto;
    }
  }
  &__image {
    width: 80vw;
    height:80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__buttons {
    margin:1rem 0;
    order:2;
    display: flex;
    gap: 1rem;
  }

}